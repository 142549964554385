body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout .sui-layout-body {
  background-color: white;
}


.layoutDark .sui-layout-body {
  background-color: #1a1a1a;

}

.layoutDark .sui-layout-body * {
  color: #fff 
}
/* Apply the style to the custom class */
.layoutDark .sui-layout-body .green-text {
  color: green; /* Your desired text color */
  font-weight: semibold; /* Your desired font weight */
  font-size: 12px; /* Your desired font size */
}

.layoutDark .sui-layout-body .red-text {
  color: red; /* Your desired text color */
  font-weight: semibold; /* Your desired font weight */
  font-size: 12px; /* Your desired font size */
}

.layoutDark .sui-layout-body .sui-select__control *,
.layoutDark .sui-facet-search__text-input {
  color: #000 !important
}

.layoutDark .sui-select__menu {
  background-color: #000 !important
}
.layoutDark .sui-layout-body .sui-facet-search__text-input {
  color: #fff !important;
}
